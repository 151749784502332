"use client";

import React, { useState } from "react";
import Background from "@/images/citypipes.webp";
import {
    Typography,
    Card,
    CardHeader,
    CardBody,
    Input,
    Button,
    CardFooter,
} from "@material-tailwind/react";
import {  useRouter } from "next/navigation";
import Link from 'next/link'
import {PmStore} from "../../state/store";
import {gql, useMutation} from "@apollo/client";
import toast from "react-hot-toast";
import eventBus, {AuthenticationLoggedInEvent} from "../../state/EventBus";

const Page = () => {
    const route = useRouter();
    const state = PmStore();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const loginQuery = gql`
    mutation login($email: String!, $password: String!) {
      login(emailAddress: $email, password: $password, appId:"d372cf0a-d449-4f73-92d4-9a279d35caf1")
    }
  `;

    const [apiLogin, { loading: apiLoginLoading }] = useMutation(loginQuery);

    function SubmitLogin(e) {
        e.preventDefault();



        apiLogin({
            variables: {
                email: email,
                password: password,
            },
        })
            .then((result) => {
                let data = result.data.login;
                state.setToken(data);



                setEmail("");
                setPassword("");
                toast.success("Successfully Authenticated!");
                eventBus.next({
                    type: AuthenticationLoggedInEvent,
                    payload: null,
                });
                route.push("/manage");
            })
            .catch((error) => {
                toast.error("Login Failure");
            });
    }

    return (
        <section className=" w-full">
            <div className="relative h-[94vh] w-full overflow-hidden ">
                <img
                    src={Background.src}
                    alt="background "
                    className="h-full w-full object-cover"
                />
                <div className="absolute top-0 left-0 h-full w-full bg-black/30" />
            </div>
            <div className="container mx-auto -mt-[80vh] grid justify-center">
                <Card className="mx-4 md:w-[26rem]">
                    <CardHeader
                        color="gray"
                        className="mb-4 grid place-items-center py-8 px-4 text-center"
                    >
                        <Typography variant="h3" color="white" className="mb-2">
                            Sign In
                        </Typography>
                        <Typography variant="small" color="white">
                            Enter your email and password to sign in
                        </Typography>
                    </CardHeader>
                    <CardBody className="flex flex-col gap-4">
                        <form onSubmit={SubmitLogin}>
                            <Typography variant="h6" color="blue-gray" className="mb-3">
                                Email
                            </Typography>

                            <input type="email" disabled={apiLoginLoading}
                                   className="w-full pl-3 pr-3 py-2 bg-transparent placeholder:text-slate-400 text-slate-600 text-sm border border-slate-200 rounded-md transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow"
                                   placeholder="Email Address" onChange={(e) => {
                                setEmail(e.target.value);
                            }}/>




                            <Typography variant="h6" color="blue-gray" className="mb-3 mt-3">
                                Password
                            </Typography>


                            <input type="password" disabled={apiLoginLoading}
                                   className="w-full pl-3 pr-3 py-2 bg-transparent placeholder:text-slate-400 text-slate-600 text-sm border border-slate-200 rounded-md transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow"
                                   placeholder="Your password" onChange={(e) => {
                                setPassword(e.target.value);
                            }}/>


                            <Button type="submit" color="gray" className="mt-4" fullWidth disabled={apiLoginLoading}>
                                Sign In
                            </Button>
                        </form>
                    </CardBody>
                    <CardFooter>
                        <div className="flex justify-between  w-full">
              <span>
                <Link href={"/login/register"}>Register</Link>
              </span>
                            <span>
                <Link href={"/login/reset"}>Reset</Link>
              </span>
                        </div>
                    </CardFooter>
                </Card>
            </div>
        </section>
    );
};

export default Page;
